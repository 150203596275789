import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageManagerService, StorageType } from '../storage/storage-manager.service';

@Injectable({
  providedIn: 'root'
})
export class HttpManagerService {

  apiUrl: String = "https://app.cpw.ac.th/StandardCanmobileApi/api/"

  constructor(
    public http: HttpClient,
    public storage: StorageManagerService
  ) { }

  onFetch(method: String, data): Observable<ArrayBuffer> {
    
    return this.http.post<any>(this.apiUrl + "" + method + "", data)
  }

  onGet(method: String): Observable<ArrayBuffer> {
    const myheaders = {
      'Authorization': 'Bearer ' + this.storage.load(StorageType.token_login)
    };
    return this.http.get<any>(this.apiUrl + "" + method, { headers: myheaders })
  }

  onPost(method: String, data): Observable<ArrayBuffer> {
    const myheaders = {
      'Authorization': 'Bearer ' + this.storage.load(StorageType.token_login),
      'Content-Type': 'application/json'
    };
    return this.http.post<any>(this.apiUrl + "" + method + "", data, { headers: myheaders })
  }
  
  
}

