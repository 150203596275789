import { Component, OnInit } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Events } from '@ionic/angular';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  constructor(public translate: TranslateService,
    private events: Events) { }

  ngOnInit() {
  }

  test() {
    this.translate.use('en');
    this.events.publish("langChangeMenu:event");
    // alert("click");
  }

}
