import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-emp-salary-detail',
  templateUrl: './emp-salary-detail.page.html',
  styleUrls: ['./emp-salary-detail.page.scss'],
})
export class EmpSalaryDetailPage implements OnInit {

  dataList: any;

  constructor(private modalCtrl: ModalController) { 

    this.dataList = [
      {
        year_edu: 'รอบ 11/2562',
        dateupd: '',
        summary: '0',
        current: '10',
        use: '2',
        total: '8',        
        expanded: false,
        // ddetail: [
        //   {
        //     ddate: '1 มิ.ย. 62',
        //     dname: 'ลาพักร้อน',
        //     score: '5',
        //   },
        //   {
        //     ddate: '2 มิ.ย. 62',
        //     dname: 'ลาพักร้อน',
        //     score: '5',
        //   }
        // ]
      }
      // ,
      // {
      //   year_edu: 'สิทธิ์ลาป่วย',
      //   dateupd: '',
      //   summary: '0',
      //   current: '30',
      //   use: '0',
      //   total: '30',
      //   expanded: false,
      //   ddetail: [
      //     {
      //       ddate: '',
      //       dname: '',
      //       score: '',
      //     }
      //   ]
      // }
      
    ];

  }

  ngOnInit() {
  }

  expandItemOpen(item){

    this.dataList.map((listItem) => {

      if (item == listItem) {
        listItem.expanded = true; 
 
        return listItem;
      } 
      return listItem;

    });
  }

  
  expandItemClose(item) {
    this.dataList.map((listItem) => {

      if (item == listItem) {
        listItem.expanded = false;
        return listItem;
      }

      return listItem;

    });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
