import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageManagerService {

  constructor() { }

  load(type: StorageType): string{
    return localStorage[type] == undefined ? "" : localStorage[type];
  }

  save(type: StorageType, value: string){
    localStorage[type] = value
  }
}


export enum StorageType {
  land,
  url,
  authen,
  token_login,
  token_notification,
  news_id,
  noti_id,
  traning_project_id,
  traning_year,
  traning_lot_id,
  tran_filter_y,
  tran_filter_y_id,
  tran_filter_p,
  tran_filter_p_id,
  tran_filter_l,
  tran_filter_l_id,
  ot_dep_filter_start,
  ot_dep_filter_stop,
  ot_dep_filter_emp,
  ot_dep_filter_emp_id,
  ot_dep_filter_status,
  ot_dep_filter_status_id,
  profile_img,
  profile_name,
  profile_depart,
  sc_creadit,
  notification_count,
  select_lat,
  select_lng,
  select_address,
  inout_filter_type,
  inout_filter_year,
  inout_filter_month,
  inout_filter_start,
  inout_filter_stop
}

export enum AuthenType {
  guest,
  admin1,
  admin2
}