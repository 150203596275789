import { Component } from '@angular/core';

import { Platform, NavController, Events, PopoverController, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { forkJoin } from 'rxjs';
// import { Observable } from 'rxjs/Observable'; 
import { Pages } from './interfaces/pages';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { LanguageComponent } from './components/language/language.component';
import { global } from './components/global/global.component';

import { Router } from '@angular/router';

import { NativePageTransitions, NativeTransitionOptions } from '@ionic-native/native-page-transitions/ngx';

import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { RouteHttpService } from './manager/http/route/route-http.service';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { StorageManagerService, StorageType, AuthenType } from './manager/storage/storage-manager.service';
import { AlertManagerService } from './manager/alert/alert-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  oldState = ""

  model = {
    profile_img: "",
    profile_name: "",
    profile_depart: "",
    sc_creadit: ""
  }

  public appPages: Array<Pages>;
  public menuList = []

  menu_edit: string = '';
  person_name: string = "";
  person_dep: string = '';
  lang_url: string = '';
  lang_text: string = '';
  popover;

  p_home: string = '';
  m_1: string = '';
  m_2: string = '';
  m_3: string = '';
  m_11: string = '';
  m_12: string = '';
  m_13: string = '';
  m_14: string = '';
  m_15: string = '';
  m_16: string = '';
  m_17: string = '';
  m_21: string = '';
  m_22: string = '';
  m_23: string = '';
  m_24: string = '';
  m_24_1: string = '';

  m_31: string = '';
  m_32: string = '';
  m_33: string = '';

  m_1001: string = '';
  m_1002: string = '';
  m_1003: string = '';
  m_1004: string = '';
  m_1005: string = '';
  m_1006: string = '';
  m_1007: string = '';
  m_1008: string = '';

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public translate: TranslateService,
    private events: Events,
    public popoverCtrl: PopoverController,
    private router: Router,
    public navCtrl: NavController,
    private nativePageTransitions: NativePageTransitions,
    private socialSharing: SocialSharing,
    private http: RouteHttpService,
    private firebase: FirebaseX,
    private storage: StorageManagerService,
    private alertManager: AlertManagerService,
    private alertController: AlertController
  ) {

    translate.setDefaultLang(global.language);
    translate.use(global.language);

    this.getLang();

    this.bindLang();

    // translate.get('EDIT_PROFILE').subscribe(value => {this.menu_edit = value;});
    this.person_name = "พรเจตน์ สงขำ";
    this.person_dep = "ฝ่ายผลิด";

    this.initialMenu();

    this.initializeApp();

    this.changeLang();

    this.getInfomation()


    this.checkSetLanguage()

    setTimeout(() => {
      this.setupFirebase()
    }, 2000);

    setInterval(() => {
      this.checkValueChange()
      this.initialMenu()
    }, 1000)
  }

  setupFirebase() {
    this.firebase.getToken().then(token => {
      console.log(`The token is ${token}`)
      this.storage.save(StorageType.token_notification, token)
    }, error => {
      console.log("111 error ")
      console.log(error)
    })

    this.firebase.onMessageReceived().subscribe(data => {
      console.log(`FCM message: ${data}`)
      console.log(data)

      let title = "";
      let body = "";
      let id = "";
      let type = "";
      // alert(JSON.stringify(data));
      this.http.getInfomation()

      if (data["body"] != undefined && data["body"] != null) {
        // this.alerts.show(data["body"])
        body = data["body"];
      }

      if (data["title"] != undefined && data["title"] != null) {
        // this.alerts.show(data["body"])
        title = data["title"];
      }

      if ((data["title"] != undefined && data["title"] != null) && (data["title"] != undefined && data["title"] != null)) {
        id = data["id"]
        type = data["type"]
      }

      // this.presentAlert(title, body, id, type);
      this.alertManager.show(body)

    }, error => {
      console.log("2222 error ")
      console.log(error)
    });
  }

  async presentAlert(title, body, id, type) {
    var btn = [{
      text: 'ปิด',
      handler: data => {
        console.log('Cancel clicked');
      }
    }]
    // if (id != "" && type != "") {
    //   btn.push({
    //     text: 'ดู',
    //     handler: data => {
    //       this.storage.save(StorageType.detail_id, id)
    //       this.storage.save(StorageType.detail_type, type)
    //       this.router.navigate(["detail"])
    //     }
    //   })
    // }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'แจ้งเตือน',
      subHeader: title,
      message: body,
      buttons: btn
    });

    await alert.present();
  }

  initialMenu() {

    if (this.oldState == this.storage.load(StorageType.authen)) {
      return
    }
    this.oldState = this.storage.load(StorageType.authen)

    setTimeout(() => {
      this.menuList = []
      this.menuList.push(
        {
          key: '1',
          gmenu: this.m_1,
          pages: [
            {
              key: '1.1',
              title: this.m_11,
              url: '/home-results',
              icon: 'home'
            },
            {
              key: '1.2',
              title: this.m_12,
              url: '/calendar',
              icon: 'calendar'
            },
            {
              key: '1.6',
              title: this.m_16,
              url: '/company-welfare',
              icon: 'document'
            },
            {
              key: '1.7',
              title: this.m_17,
              url: '/company-contact',
              icon: 'contact'
            },

          ]
        }
      )
      this.menuList.push(
        {
          key: '2',
          gmenu: this.m_2,
          pages: [
            {
              key: '1004',
              title: this.m_1004,
              url: '/checkin-outdoor',
              icon: 'checkmark'
            },
            {
              key: '1001',
              title: this.m_1001,
              url: '/inout-realtime',
              icon: 'swap'
            },
            {
              key: '1.5',
              title: this.m_15,
              url: '/inout-summary',
              icon: 'document'
            },
            {
              key: '2.4',
              title: this.m_24,
              url: '/ot-summary',
              // open: false,
              icon: 'time',
              // children: [
              //   {
              //     title: this.m_24_1,
              //     url: '/ot-list',
              //     icon: 'ios-arrow-forward'
              //   },
              //   {
              //     title: 'สรุปข้อมูล OT',
              //     url: '/ot-summary',
              //     icon: 'ios-arrow-forward'
              //   }
              // ]
            }, {
              key: '1005',
              title: this.m_1005,
              url: '/emp-benefits',
              icon: 'document'
            },
            {
              key: '1.3',
              title: this.m_13,
              open: false,
              icon: 'calendar',
              children: [
                {
                  key: '1.3.1',
                  title: 'ตารางปฏิทิน',
                  url: '/schedule',
                  icon: 'ios-arrow-forward'
                },
                {
                  key: '1.3.2',
                  title: 'รายการนัดหมาย(ลงเวลา)',
                  url: '/pending-calendar',
                  icon: 'ios-arrow-forward'
                }
              ]
            },
            {
              key: '2.3',
              title: this.m_23,
              url: '/behave',
              icon: 'medal'
            },
            // {
            //   key: '2.1',
            //   title: this.m_21,
            //   url: '/benefits',
            //   icon: 'ribbon'
            // },
            {
              key: '2.2',
              title: this.m_22,
              url: '/pay-slip',
              icon: 'list-box'
            },
            {
              key: '3.3',
              title: this.m_33,
              url: '/edit-profile',
              icon: 'person'
            },
          ]
        }
      )

      if (this.storage.load(StorageType.authen) == AuthenType.admin2.toString()) {
        this.menuList.push(
          {
            key: '4',
            gmenu: "เกี่ยวกับลูกน้อง",
            pages: [
              {
                key: '1001',
                title: this.m_1001,
                url: '/inout-emp-realtime',
                icon: 'swap'
              },
              {
                key: '1006',
                title: this.m_1006,
                url: '/ot-depart-summary',
                icon: 'time'
              },
              {
                key: '1007',
                title: this.m_1007,
                url: '/leave-emp-summary',
                icon: 'time'
              },
              {
                key: '1008',
                title: this.m_1008,
                url: '/modal-iframe',
                icon: 'link'
              }
            ]
          }
        )
      }

      this.menuList.push(
        {
          key: '3',
          gmenu: this.m_3,
          pages: [
            {
              key: '3.1',
              title: this.m_31,
              url: 'settings',
              icon: 'cog'
            },
            {
              key: '3.2',
              title: this.m_32,
              url: '/change-password',
              icon: 'unlock'
            },
            {
              key: '1002',
              title: this.m_1002,
              url: '/edit-profile',
              icon: 'document'
            }, {
              key: '1003',
              title: this.m_1003,
              url: '/edit-profile',
              icon: 'mail'
            }
          ]
        }
      )

    }, 500);
  }

  getLang() {
    this.lang_url = '/assets/images/language/' + global.language + '.jpg';
    this.translate.get('LANGUAGE').subscribe(value => { this.lang_text = value; });


    this.translate.get('MAIN_MENU').subscribe(value => { this.m_1 = value; });
    this.translate.get('MENU_PRIVATE').subscribe(value => { this.m_2 = value; });
    this.translate.get('MENU_PROGRAM').subscribe(value => { this.m_3 = value; });
    this.translate.get('NEWS_MENU').subscribe(value => { this.m_11 = value; });
    this.translate.get('CALENDAR').subscribe(value => { this.m_12 = value; });
    this.translate.get('SCHEDULE').subscribe(value => { this.m_13 = value; });
    this.translate.get('BOOKING_ROOM').subscribe(value => { this.m_14 = value; });
    this.translate.get('OPERAT_INFO').subscribe(value => { this.m_15 = value; });
    this.translate.get('MENU_WELFARE').subscribe(value => { this.m_16 = value; });
    this.translate.get('MENU_COM_CONTACT').subscribe(value => { this.m_17 = value; });
    this.translate.get('POINTS_REWARDS').subscribe(value => { this.m_21 = value; });
    this.translate.get('PAY_SLIP').subscribe(value => { this.m_22 = value; });
    this.translate.get('BEHAVE').subscribe(value => { this.m_23 = value; });
    this.translate.get('MENU_OT').subscribe(value => { this.m_24 = value; });
    this.translate.get('MENU_OT_QUOTA').subscribe(value => { this.m_24_1 = value; });

    this.translate.get('APP_SETTING').subscribe(value => { this.m_31 = value; });
    this.translate.get('CHANGE_PASS').subscribe(value => { this.m_32 = value; });
    this.translate.get('PROFILE').subscribe(value => { this.m_33 = value; });

    this.translate.get('MENU_INOUT_REALTIME').subscribe(value => { this.m_1001 = value; });
    this.translate.get('MENU_USER_MANUAL').subscribe(value => { this.m_1002 = value; });
    this.translate.get('MENU_REPORt_PROBLEM').subscribe(value => { this.m_1003 = value; });
    this.translate.get('MENU_INOUT_OUT').subscribe(value => { this.m_1004 = value; });
    this.translate.get('MENU_WELFARE').subscribe(value => { this.m_1005 = value; });
    this.translate.get('MENU_OT_DEPART').subscribe(value => { this.m_1006 = value; });
    this.translate.get('MENU_LEAVE_EMP').subscribe(value => { this.m_1007 = value; });
    this.translate.get('LEAVE_SYSTEM').subscribe(value => { this.m_1008 = value; });

  }

  bindLang() {

    this.getLang();

    setTimeout(() => {
      if (this.menuList == null)
        return;

      let mcount = this.menuList.length;
      for (let i = 0; i < mcount; i++) {
        let menu = this.menuList[i];
        if (menu.key == '1')
          menu.gmenu = this.m_1;
        else if (menu.key == '2')
          menu.gmenu = this.m_2;
        else if (menu.key == '3')
          menu.gmenu = this.m_3;

        let scount = menu.pages.length;
        for (let j = 0; j < scount; j++) {
          let smenu = menu.pages[j];
          if (smenu.key == '1.1')
            smenu.title = this.m_11;
          else if (smenu.key == '1.2')
            smenu.title = this.m_12;
          else if (smenu.key == '1.3')
            smenu.title = this.m_13;
          else if (smenu.key == '1.4')
            smenu.title = this.m_14;
          else if (smenu.key == '1.5')
            smenu.title = this.m_15;
          else if (smenu.key == '1.6')
            smenu.title = this.m_16;
          else if (smenu.key == '1.7')
            smenu.title = this.m_17;
          else if (smenu.key == '2.1')
            smenu.title = this.m_21;
          else if (smenu.key == '2.2')
            smenu.title = this.m_22;
          else if (smenu.key == '2.3')
            smenu.title = this.m_23;
          else if (smenu.key == '2.4')
            smenu.title = this.m_24;
          else if (smenu.key == '2.4')
            smenu.title = this.m_24_1;
          else if (smenu.key == '3.1')
            smenu.title = this.m_31;
          else if (smenu.key == '3.2')
            smenu.title = this.m_32;
          else if (smenu.key == '3.3')
            smenu.title = this.m_33;
          else if (smenu.key == '1001')
            smenu.title = this.m_1001;
          else if (smenu.key == '1002')
            smenu.title = this.m_1002;
          else if (smenu.key == '1003')
            smenu.title = this.m_1003;
          else if (smenu.key == '1004')
            smenu.title = this.m_1004;
          else if (smenu.key == '1005')
            smenu.title = this.m_1005;
          else if (smenu.key == '1006')
            smenu.title = this.m_1006;
          else if (smenu.key == '1007')
            smenu.title = this.m_1007;
          else if (smenu.key == '1008')
            smenu.title = this.m_1008;
        }

      }

    }, 200);
  }

  changeLang() {

    this.events.subscribe('langChangeMenu:event', () => {

      this.translate.use(global.language);
      this.bindLang();

      if (this.router.url == '/home-results') {
        this.events.publish("langChangeHome:event");
      }

    });

  }

  async sendProplem(ev?: any) {
    let _mailProblem = "pornjate.skm@gmail.com";
    this.socialSharing.shareViaEmail('เรียน เจ้าหน้าที่<br><br><br>', 'แจ้งปัญหาการใช้งาน', [_mailProblem]).then(() => {
      // Success!
    }).catch(() => {
      // Error!
    });
  }

  async openUserManual(ev?: any) {
    window.open('https://www.standardcan.com/');
  }


  async changeLangClick(ev?: any) {
    // alert("xx");
    this.popover = await this.popoverCtrl.create({
      component: LanguageComponent,
      event: ev,
      animated: true,
      showBackdrop: true
    });

    // this.popover.onDidDismiss().then((dataReturned) => {
    //   if (dataReturned !== null) {
    //     // this.dataReturned = dataReturned.data;
    //     //alert('Modal Sent Data :'+ dataReturned);
    //   }
    // });

    return await this.popover.present();
  }

  initializeApp() {

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    }).catch(() => { });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // alert("change");

      // this.translate.get('COM_NAME'),
      // this.translate.get('FORMS'),
      // this.translate.get('FUNCTIONALITIES'),
      // this.translate.get('LAYOUTS'),
      // this.translate.get('SETTINGS'),
      // this.translate.get('WORDPRESS_INTEGRATION'),
      // this.translate.get('FIREBASE_INTEGRATION')

      // Observable.forkJoin(
      //   this.translate.get('HOME'),
      //   this.translate.get('FORMS'),
      //   this.translate.get('FUNCTIONALITIES'),
      //   this.translate.get('LAYOUTS'),
      //   this.translate.get('SETTINGS'),
      //   this.translate.get('WORDPRESS_INTEGRATION'),
      //   this.translate.get('FIREBASE_INTEGRATION'),
      // ).subscribe(data => {
      //   //console.log(data)
      // });

    });
  }

  goToEditProgile() {
    let options: NativeTransitionOptions = {
      direction: 'up',
      duration: 500,
      slowdownfactor: 3,
      slidePixels: 20,
      iosdelay: 100,
      androiddelay: 150,
      fixedPixelsTop: 0,
      fixedPixelsBottom: 60
    }

    this.nativePageTransitions.slide(options);
    // this.navCtrl.navigateForward('edit-profile');

  }

  logout() {
    // this.translate.use('en');
    this.storage.save(StorageType.authen, AuthenType.guest.toString())
    this.navCtrl.navigateRoot('/');
  }

  getInfomation() {
    // if (this.http.isTokenActive()) {
    //   this.http.getInfomation().subscribe(
    //     response => {
    //       console.log(response)
    //       if(this.http.onCheckSuccess(response)){
    //         StorageType
    //         // this.model = response
    //       }
    //     }
    //   )
    // }
    // this.http.getInfomation()
  }

  checkValueChange() {
    this.model.profile_depart = this.storage.load(StorageType.profile_depart)
    this.model.profile_img = this.storage.load(StorageType.profile_img)
    this.model.profile_name = this.storage.load(StorageType.profile_name)
    this.model.sc_creadit = this.storage.load(StorageType.sc_creadit)
  }

  checkSetLanguage(){
    var l = this.storage.load(StorageType.land)
    if(l == ""){
      l = "th"
      this.storage.save(StorageType.land, "th")
    }

    global.language = this.storage.load(StorageType.land)
    this.events.publish("langChangeMenu:event");
  }
}
