import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-global',
  templateUrl: './global.component.html',
  styleUrls: ['./global.component.scss'],
})
export class GlobalComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
 
export var global = {
  user_id: '',
  user_name: '',
  language: 'th',
};
