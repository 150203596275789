import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Location } from '@angular/common';
import { RouteHttpService } from 'src/app/manager/http/route/route-http.service';
import { StorageManagerService, StorageType } from 'src/app/manager/storage/storage-manager.service';
import { AlertManagerService } from 'src/app/manager/alert/alert-manager.service';

@Component({
  selector: 'app-modal-leave-search',
  templateUrl: './modal-leave-search.page.html',
  styleUrls: ['./modal-leave-search.page.scss'],
})
export class ModalLeaveSearchPage implements OnInit {

  models = {
    emps: [],
    emp: "",
    statuss: [],
    status: "",
    start: "",
    stop: ""
  }

  public radiusmiles = 1;
  public minmaxprice = {
    upper: 500,
    lower: 10
  };

  public organizeby = "ทั้งหมด";
  public dishtype = "พฤษภาคม";
  public dishnationality = "ทั้งแผนก";



  constructor(
    private modalCtrl: ModalController,
    private location: Location,
    private http: RouteHttpService,
    private storageManager: StorageManagerService,
    private alertManager: AlertManagerService
    ) { 
      this.loadFilter()
    }

  ngOnInit() {
  }

  closeModal() {
    this.storageManager.save(StorageType.ot_dep_filter_start, this.models.start)
    this.storageManager.save(StorageType.ot_dep_filter_stop, this.models.stop)
    this.storageManager.save(StorageType.ot_dep_filter_emp, this.models.emp)
    this.storageManager.save(StorageType.ot_dep_filter_emp_id, this.getEmpId())
    this.storageManager.save(StorageType.ot_dep_filter_status, this.models.status)
    this.storageManager.save(StorageType.ot_dep_filter_status_id, this.getStatusId())
    this.location.back()
  }

  loadFilter(){
    this.http.getOtDepartFilter().subscribe(
      response => {
        console.log(response)
        if(this.http.onCheckSuccess(response)){
          this.models.emps = response["emps"]
          this.models.statuss = response["status"]
          this.models.start = this.storageManager.load(StorageType.ot_dep_filter_start)
          this.models.stop = this.storageManager.load(StorageType.ot_dep_filter_stop)
          this.models.emp = this.storageManager.load(StorageType.ot_dep_filter_emp)
          this.models.status = this.storageManager.load(StorageType.ot_dep_filter_status)
        }
      }
    )
  }

  getEmpId(){
    var i = ""
    this.models.emps.forEach(item => {
      if(item["name"] == this.models.emp){
        i = item["emp_id"]
      }
    })
    return i
  }

  getStatusId(){
    var i = ""
    this.models.statuss.forEach(item => {
      if(item["name"] == this.models.status){
        i = item["sts_id"]
      }
    })
    return i
  }

}