import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';


import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// Modal Pages
import { ImagePageModule } from './pages/modal/image/image.module';
import { SearchFilterPageModule } from './pages/modal/search-filter/search-filter.module';
import { ModalInoutSearchPageModule } from './pages/modal-inout-search/modal-inout-search.module';
import { ModalLeaveSearchPageModule } from './pages/modal-leave-search/modal-leave-search.module';
import { ModalProjectSearchPageModule } from './pages/modal-project-search/modal-project-search.module';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { EmpSalaryDetailPageModule } from './pages/emp-salary-detail/emp-salary-detail.module'

// Components
import { NotificationsComponent } from './components/notifications/notifications.component';
import { LanguageComponent } from './components/language/language.component';
import { GlobalComponent } from './components/global/global.component';
// import { BackgroundImageComponent } from './components/background-image/background-image.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
import { NgCalendarModule } from 'ionic2-calendar';
import { NativePageTransitions, NativeTransitionOptions } from '@ionic-native/native-page-transitions/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { HttpManagerService } from './manager/http/http-manager.service';
import { RouteHttpService } from './manager/http/route/route-http.service';
import { StorageManagerService } from './manager/storage/storage-manager.service';
import { AlertManagerService } from './manager/alert/alert-manager.service';

import { Camera } from '@ionic-native/camera/ngx';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { QRScanner } from '@ionic-native/qr-scanner/ngx';

@NgModule({
  declarations: [AppComponent, NotificationsComponent, LanguageComponent, GlobalComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ImagePageModule,
    SearchFilterPageModule,
    ModalInoutSearchPageModule,EmpSalaryDetailPageModule,
    ModalLeaveSearchPageModule, ModalProjectSearchPageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    NgCalendarModule
  ],
  entryComponents: [NotificationsComponent, LanguageComponent],
  providers: [
    StatusBar, 
    SplashScreen, 
    NativePageTransitions, 
    SocialSharing,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    HttpManagerService,
    RouteHttpService,
    StorageManagerService,
    AlertManagerService,
    Camera,
    Geolocation,
    QRScanner,
    FirebaseX
  ], exports: [
    TranslateModule
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})

export class AppModule { }
