import { Injectable } from '@angular/core';
import { LoadingController, AlertController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertManagerService {

  constructor(
    public loadingController: LoadingController,
    private alertController: AlertController,
    public toastCtrl: ToastController
  ) { }

  show(message: String, state: AlertType = AlertType.success) {
    console.log(message)
    // alert(message)
    this.presentAlert("", message)
  }

  async toast(message: string){
    const toast = await this.toastCtrl.create({
      showCloseButton: true,
      message: message,
      duration: 3000,
      position: 'top'
    });

    toast.present();
  }

  async presentAlert(title, body) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'แจ้งเตือน',
      // subHeader: title,
      message: body,
      buttons: ["ตกลง"]
    });

    await alert.present();
  }

  async startLoading() {
    var loading = await this.loadingController.create({
      message: 'กำลังประมวลผล'
    });
    loading.present();
  }

  stopLoading() {
    // setTimeout(() => {
    //   this.loadingController.dismiss()
    // }, 1000);
    this.loadingController.dismiss()
  }

  startLoadingNew(){
    this.startLoading();
    setTimeout(() => {
      this.stopLoadingNew()
    }, 3000);
  }

  stopLoadingNew(){
    this.stopLoading();
    setTimeout(() => {
      this.stopLoading();
    }, 500);
    setTimeout(() => {
      this.stopLoading();
    }, 1500);
  }

}

export enum AlertType {
  success,
  failed
}
