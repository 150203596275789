import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'register', loadChildren: './pages/register/register.module#RegisterPageModule' },
  { path: 'about', loadChildren: './pages/about/about.module#AboutPageModule' },
  { path: 'settings', loadChildren: './pages/settings/settings.module#SettingsPageModule' },
  { path: 'edit-profile', loadChildren: './pages/edit-profile/edit-profile.module#EditProfilePageModule' },
  { path: 'home-results', loadChildren: './pages/home-results/home-results.module#HomeResultsPageModule' },
  { path: 'news-detail', loadChildren: './pages/news-detail/news-detail.module#NewsDetailPageModule' },
  { path: 'schedule', loadChildren: './pages/schedule/schedule.module#SchedulePageModule' }, 
  { path: 'change-password', loadChildren: './pages/change-password/change-password.module#ChangePasswordPageModule' },
  { path: 'noti', loadChildren: './pages/noti/noti.module#NotiPageModule' },
  { path: 'calendar', loadChildren: './pages/calendar/calendar.module#CalendarPageModule' },
  { path: 'pay-slip', loadChildren: './pages/pay-slip/pay-slip.module#PaySlipPageModule' },
  { path: 'behave', loadChildren: './pages/behave/behave.module#BehavePageModule' },
  { path: 'benefits', loadChildren: './pages/benefits/benefits.module#BenefitsPageModule' },
  { path: 'inout-summary', loadChildren: './pages/inout-summary/inout-summary.module#InoutSummaryPageModule' },
  { path: 'ot-summary', loadChildren: './pages/ot-summary/ot-summary.module#OtSummaryPageModule' },
  { path: 'pending-calendar', loadChildren: './pages/pending-calendar/pending-calendar.module#PendingCalendarPageModule' },
  { path: 'booking-room', loadChildren: './pages/booking-room/booking-room.module#BookingRoomPageModule' },
  { path: 'booking-room-list', loadChildren: './pages/booking-room-list/booking-room-list.module#BookingRoomListPageModule' },
  { path: 'ot-list', loadChildren: './pages/ot-list/ot-list.module#OtListPageModule' },
  { path: 'ot-pending', loadChildren: './pages/ot-pending/ot-pending.module#OtPendingPageModule' },
  { path: 'booking-room-confirm', loadChildren: './pages/booking-room-confirm/booking-room-confirm.module#BookingRoomConfirmPageModule' },
  { path: 'booking-car-confirm', loadChildren: './pages/booking-car-confirm/booking-car-confirm.module#BookingCarConfirmPageModule' },
  { path: 'leave-confirm', loadChildren: './pages/leave-confirm/leave-confirm.module#LeaveConfirmPageModule' },
  { path: 'ot-summary-boss', loadChildren: './pages/ot-summary-boss/ot-summary-boss.module#OtSummaryBossPageModule' },
  { path: 'inout-summary-boss', loadChildren: './pages/inout-summary-boss/inout-summary-boss.module#InoutSummaryBossPageModule' },
  { path: 'benefits-info', loadChildren: './pages/benefits-info/benefits-info.module#BenefitsInfoPageModule' },
  { path: 'test', loadChildren: './test/test.module#TestPageModule' },
  { path: 'company-contact', loadChildren: './pages/company-contact/company-contact.module#CompanyContactPageModule' },
  { path: 'company-welfare', loadChildren: './pages/company-welfare/company-welfare.module#CompanyWelfarePageModule' },
  { path: 'inout-realtime', loadChildren: './pages/inout-realtime/inout-realtime.module#InoutRealtimePageModule' },
  { path: 'checkin-outdoor', loadChildren: './pages/checkin-outdoor/checkin-outdoor.module#CheckinOutdoorPageModule' },
  { path: 'emp-benefits', loadChildren: './pages/emp-benefits/emp-benefits.module#EmpBenefitsPageModule' },
  { path: 'inout-emp-realtime', loadChildren: './pages/inout-emp-realtime/inout-emp-realtime.module#InoutEmpRealtimePageModule' },
  { path: 'ot-depart-summary', loadChildren: './pages/ot-depart-summary/ot-depart-summary.module#OtDepartSummaryPageModule' },
  { path: 'leave-emp-summary', loadChildren: './pages/leave-emp-summary/leave-emp-summary.module#LeaveEmpSummaryPageModule' },
  { path: 'modal-leave-search', loadChildren: './pages/modal-leave-search/modal-leave-search.module#ModalLeaveSearchPageModule' },
  { path: 'modal-iframe', loadChildren: './pages/modal-iframe/modal-iframe.module#ModalIframePageModule' },
  { path: 'modal-project-search', loadChildren: './pages/modal-project-search/modal-project-search.module#ModalProjectSearchPageModule' },
  { path: 'salary-detail', loadChildren: './salary-detail/salary-detail.module#SalaryDetailPageModule' },
  { path: 'emp-salary-detail', loadChildren: './pages/emp-salary-detail/emp-salary-detail.module#EmpSalaryDetailPageModule' },
  { path: 'map-select', loadChildren: './pages/map-select/map-select.module#MapSelectPageModule' },
  { path: 'scanqrcode', loadChildren: './pages/scanqrcode/scanqrcode.module#ScanqrcodePageModule' },
  { path: 'map-detail', loadChildren: './pages/map-detail/map-detail.module#MapDetailPageModule' },
  { path: 'modal-inout-search', loadChildren: './pages/modal-inout-search/modal-inout-search.module#ModalInoutSearchPageModule' },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule] 
})

export class AppRoutingModule {}
