import { Component, OnInit } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Events, PopoverController } from '@ionic/angular';

import { global } from './../global/global.component';
import { StorageManagerService, StorageType } from 'src/app/manager/storage/storage-manager.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageComponent implements OnInit {

  constructor(public translate: TranslateService,
    private events: Events,
    private storage: StorageManagerService,
    private location: Location,
    private popoverCtrl: PopoverController) { }

  ngOnInit() { }


  async  selectlang(lang) {
    this.storage.save(StorageType.land, lang)
    global.language = lang;

    this.events.publish("langChangeMenu:event");

    this.closeModal();
    window.location.reload()
  }

  closeModal() {

    this.popoverCtrl.dismiss();

  }

}
