import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Location } from '@angular/common';
import { RouteHttpService } from 'src/app/manager/http/route/route-http.service';
import { StorageManagerService, StorageType } from 'src/app/manager/storage/storage-manager.service';
import { AlertManagerService } from 'src/app/manager/alert/alert-manager.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { global } from './../../components/global/global.component';
import {
  NavController,
  AlertController,
  MenuController,
  ToastController,
  PopoverController,
  IonSlides,
  Events
} from '@ionic/angular';
@Component({
  selector: 'app-modal-inout-search',
  templateUrl: './modal-inout-search.page.html',
  styleUrls: ['./modal-inout-search.page.scss'],
})
export class ModalInoutSearchPage implements OnInit {

  models = {
    types: ["ปี", "เดือน", "วัน"],
    years: [],
    months: ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฏาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤษจิกายน", "ธันวาคม"],
    type: "",
    year: "",
    month: "",
    start: "",
    stop: ""
  }

  public radiusmiles = 1;
  public minmaxprice = {
    upper: 500,
    lower: 10
  };

  public organizeby = "2563";
  public dishtype = "พฤษภาคม";
  public dishnationality = "ปี";



  constructor(
    private modalCtrl: ModalController,
    private location: Location,
    private http: RouteHttpService,
    public translate: TranslateService,
    private events: Events,
    private storageManager: StorageManagerService,
    private alertManager: AlertManagerService
  ) {

    if (http.isTokenActive()) {
      http.getSummaryTimeFilter().subscribe(
        response => {
          console.log(response)
          if (http.onCheckSuccess(response)) {
            this.models.years = response["years"]

            this.models.type = storageManager.load(StorageType.inout_filter_type)
            this.models.year = storageManager.load(StorageType.inout_filter_year)
            // console.log(storageManager.load(StorageType.inout_filter_month))
            this.models.month = storageManager.load(StorageType.inout_filter_month)
            this.models.start = storageManager.load(StorageType.inout_filter_start)
            this.models.stop = storageManager.load(StorageType.inout_filter_stop)
            console.log(this.models)
          }
        }
      )
    }
    translate.use(global.language);
    this.changeLang();
}

changeLang() {

    this.events.subscribe('langChangeHome:event', () => {

      this.translate.use(global.language);


    });

  }
  ngOnInit() {
  }

  closeModal() {
    // this.modalCtrl.dismiss();
    console.log(this.models)
    this.storageManager.save(StorageType.inout_filter_type, this.models.type)
    this.storageManager.save(StorageType.inout_filter_year, this.models.year)
    this.storageManager.save(StorageType.inout_filter_month, this.models.month)
    this.storageManager.save(StorageType.inout_filter_start, this.models.start)
    this.storageManager.save(StorageType.inout_filter_stop, this.models.stop)

    this.location.back()
  }

}
