import { Injectable } from '@angular/core';
import { HttpManagerService } from '../http-manager.service';
import { StorageManagerService, StorageType, AuthenType } from '../../storage/storage-manager.service';
import * as jwt_decode from 'jwt-decode';
import { AlertManagerService } from '../../alert/alert-manager.service';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class RouteHttpService {

  constructor(
    public http: HttpManagerService,
    public storage: StorageManagerService,
    public alertManager: AlertManagerService,
    public navCtrl: NavController
  ) { }


  onDecodeToken() {
    return jwt_decode(this.storage.load(StorageType.token_login))
  }

  isTokenActive() {
    var deToken = this.onDecodeToken()
    if (Date.now() > (deToken["exp"] * 1000)) {
      console.log("TOKEN DIE")
      this.storage.save(StorageType.authen, AuthenType.guest.toString())
      this.navCtrl.navigateRoot('/');
      return false
    } else {
      return true
    }
  }

  onCheckSuccess(response, isToast = false) {
    if (response["message"]["status"] == "1") {
      if (isToast) {
        this.alertManager.show(response["message"]["msg"])
      }
      return true
    } else {
      this.alertManager.show(response["message"]["msg"])
      return false
    }
  }

  getLanguage() {
    var l = this.storage.load(StorageType.land)
    if (l == "en") {
      return "2"
    } else {
      return "1"
    }
  }

  onLogin(username, password) {
    var data = {
      "username": username,
      "password": password,
      "token_noticiation": this.storage.load(StorageType.token_notification)
    }
    console.log(JSON.stringify(data))
    return this.http.onFetch("Authentication/userlogin", data)
  }

  getUserProfile() {
    return this.http.onGet("User/GetData")
  }

  getInfomation() {
    this.http.onGet("News/GetInfomation?language=" + this.getLanguage()).subscribe(
      response => {
        console.log(response)
        this.storage.save(StorageType.profile_depart, response["profile_depart"])
        this.storage.save(StorageType.profile_img, response["profile_img"])
        this.storage.save(StorageType.profile_name, response["profile_name"])
        this.storage.save(StorageType.sc_creadit, response["sc_creadit"])
        this.storage.save(StorageType.notification_count, response["notification_count"])
      }
    )
  }

  forgotPassword(email) {
    var data = {
      "email": email,
      "language": this.getLanguage()
    }
    console.log(JSON.stringify(data))
    return this.http.onFetch("Authentication/UserResetPassword", data)
  }

  getProfile() {
    return this.http.onGet("User/GetProfile?language=" + this.getLanguage())
  }

  saveProfile(email, line, tel) {
    var data = {
      "token_login": this.storage.load(StorageType.token_login),
      "language": this.getLanguage(),
      "email": email,
      "line": line,
      "tel": tel
    }
    console.log(JSON.stringify(data))
    return this.http.onPost("User/UpdateUser", data)
  }

  changePassword(old, news) {
    var data = {
      "token_login": this.storage.load(StorageType.token_login),
      "language": this.getLanguage(),
      "old_password": old,
      "new_password": news
    }
    console.log(JSON.stringify(data))
    return this.http.onPost("User/UserChangePassword", data)
  }

  getImageSlider() {
    return this.http.onGet("News/GetImageSlide?language=" + this.getLanguage())
  }

  getNewsList(type, last_id) {
    return this.http.onGet("News/GetNewsList?language=" + this.getLanguage() + "&type=" + type + "&last_id=" + last_id)
  }
  getNewsDetail(id) {
    return this.http.onGet("News/GetNewsDetail?language=" + this.getLanguage() + "&id=" + id)
  }

  getNotificationList(last_id) {
    return this.http.onGet("Noti/GetNotiList?language=" + this.getLanguage() + "&last_id=" + last_id)
  }

  getBenefitsCompany(last_id) {
    return this.http.onGet("Company/GetBenefitsCompany?language=" + this.getLanguage() + "&last_id=" + last_id)
  }

  getCompanyContact() {
    return this.http.onGet("Company/GetAboutCompany?language=" + this.getLanguage())
  }

  getInOutRealtime() {
    return this.http.onGet("TimeInOut/GetInoutRealtime?language=" + this.getLanguage())
  }

  getSetting() {
    return this.http.onGet("Setting/GetNotiSetting?language=" + this.getLanguage())
  }

  changeSwitchNotification(status) {
    var data = {
      "token_login": this.storage.load(StorageType.token_login),
      "language": this.getLanguage(),
      "notification": status ? "true" : "false"
    }
    console.log(JSON.stringify(data))
    return this.http.onPost("Setting/NotiSetting", data)
  }

  getOtList(start, stop) {
    return this.http.onGet("Benefit/GetOTSummary?language=" + this.getLanguage() + "&start_date=" + start + "&stop_date=" + stop)
  }

  getBenefitEmployee() {
    return this.http.onGet("Benefit/GetBenefitsEmployee?language=" + this.getLanguage())
  }

  getMeetingList() {
    return this.http.onGet("Meeting/GetMeeting?language=" + this.getLanguage())
  }

  getTraningFilter() {
    return this.http.onGet("Traning/GetTraningCondition?language=" + this.getLanguage())
  }

  getTraningList() {
    var year = this.storage.load(StorageType.tran_filter_y_id)
    var project_id = this.storage.load(StorageType.tran_filter_p_id)
    var lot_id = this.storage.load(StorageType.tran_filter_l_id)
    return this.http.onGet("Traning/GetTraningDetail?language=" + this.getLanguage() + "&project_id=" + project_id + "&year=" + year + "&lot_id=" + lot_id)
  }

  getPayrollList() {
    return this.http.onGet("Payroll/GetPayroll?language=" + this.getLanguage())
  }

  getHitsoryRealtime() {
    return this.http.onGet("TimeInOut/GetInoutEmpRealtimeSearch?language=" + this.getLanguage())
  }

  getOtDepartFilter() {
    return this.http.onGet("Benefit/GetBenefitsDepartmentMaster?language=" + this.getLanguage())
  }

  getOtDepartList() {
    var start = this.storage.load(StorageType.ot_dep_filter_start)
    var stop = this.storage.load(StorageType.ot_dep_filter_stop)
    var emp = this.storage.load(StorageType.ot_dep_filter_emp_id)
    var status = this.storage.load(StorageType.ot_dep_filter_status_id)
    return this.http.onGet("Benefit/GetOTDepartmentSummary?language=" + this.getLanguage() + "&start_date=" + start + "&stop_date=" + stop + "&emp_id=" + emp + "&sts_id=" + status)
  }

  getBenefitLeave() {
    var start = this.storage.load(StorageType.ot_dep_filter_start)
    var stop = this.storage.load(StorageType.ot_dep_filter_stop)
    var emp = this.storage.load(StorageType.ot_dep_filter_emp_id)
    var status = this.storage.load(StorageType.ot_dep_filter_status_id)
    return this.http.onGet("Benefit/GetBenefitsLeave?language=" + this.getLanguage() + "&start_date=" + start + "&stop_date=" + stop + "&emp_id=" + emp + "&sts_id=" + status)
  }


  sendCheckin(lat, lng, remark, imgs) {
    var data = {
      "token_login": this.storage.load(StorageType.token_login),
      "language": this.getLanguage(),
      "lat": lat,
      "lng": lng,
      "remark": remark,
      "img": imgs,

    }
    console.log(JSON.stringify(data))
    return this.http.onPost("TimeInOut/CheckinOutdoor", data)
  }

  sendQrCode(qrcode) {
    var data = {
      "token_login": this.storage.load(StorageType.token_login),
      "language": this.getLanguage(),
      "qrcode": qrcode
    }
    console.log(JSON.stringify(data))
    return this.http.onPost("TimeInOut/CheckInTime", data)
  }

  getNotificationMap(id) {
    return this.http.onGet("User/GetNotiDetail?language=" + this.getLanguage() + "&noti_id=" + id)
  }

  getSummaryTimeFilter() {
    return this.http.onGet("TimeInOut/GetSummaryTimeFilter?language=" + this.getLanguage())
  }

  getSummaryTime() {

    var typeMaster = ["ปี", "เดือน", "วัน"]
    var typeStr = this.storage.load(StorageType.inout_filter_type)
    var type = ""
    for (var i = 0; i < typeMaster.length; i++) {
      if (typeMaster[i] == typeStr) {
        type = "" + (i + 1)
      }
    }

    var year = this.storage.load(StorageType.inout_filter_year)

    var monthMaster = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฏาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤษจิกายน", "ธันวาคม"]
    var monthStr = this.storage.load(StorageType.inout_filter_month)
    var month = ""
    for (var i = 0; i < monthMaster.length; i++) {
      if (monthMaster[i] == monthStr) {
        month = "" + (i + 1)
      }
    }

    if (month == "") {
      var m = new Date().getMonth()
      month = "" + (m + 1)
    }

    var start = this.storage.load(StorageType.inout_filter_start)
    var stop = this.storage.load(StorageType.inout_filter_stop)
    return this.http.onGet("TimeInOut/GetSummaryTime?language=" + this.getLanguage() + "&type=" + type + "&year=" + year + "&month=" + month + "&start=" + start + "&stop=" + stop)
  }

}
