import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RouteHttpService } from 'src/app/manager/http/route/route-http.service';
import { AlertManagerService } from 'src/app/manager/alert/alert-manager.service';
import { StorageManagerService, StorageType } from 'src/app/manager/storage/storage-manager.service';
import { Location } from '@angular/common';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { global } from './../../components/global/global.component';
import {
  NavController,
  AlertController,
  MenuController,
  ToastController,
  PopoverController,
  IonSlides,
  Events
} from '@ionic/angular';

@Component({
  selector: 'app-modal-project-search',
  templateUrl: './modal-project-search.page.html',
  styleUrls: ['./modal-project-search.page.scss'],
})
export class ModalProjectSearchPage implements OnInit {

  models = {
    lots: [],
    years: ["ads", "ads", "ads", "ads"],
    projects: [],
    y: "",
    p: "",
    l: ""
  }

  lots = ["dad", "aaa", "dddd"]

  public radiusmiles = 1;
  public minmaxprice = {
    upper: 500,
    lower: 10
  };

  public organizeby = "2563";
  public dishtype = "พฤษภาคม";
  public dishnationality = "ปี";



  constructor(
    private modalCtrl: ModalController,
    public http: RouteHttpService,
    public translate: TranslateService,
    private events: Events,
    public alertManager: AlertManagerService,
    public storageManager: StorageManagerService,
    public location: Location
  ) {
    this.getFilter()
    translate.use(global.language);
    this.changeLang();
}

changeLang() {

    this.events.subscribe('langChangeHome:event', () => {

      this.translate.use(global.language);


    });

  }
  ngOnInit() {
  }

  closeModal() {

    this.storageManager.save(StorageType.tran_filter_y, "")
    this.storageManager.save(StorageType.tran_filter_y_id, "")
    this.storageManager.save(StorageType.tran_filter_p, "")
    this.storageManager.save(StorageType.tran_filter_p_id, "")
    this.storageManager.save(StorageType.tran_filter_l, "")
    this.storageManager.save(StorageType.tran_filter_l_id, "")

    this.storageManager.save(StorageType.tran_filter_y, this.models.y)
    this.storageManager.save(StorageType.tran_filter_y_id, this.getYearId())
    this.storageManager.save(StorageType.tran_filter_p, this.models.p)
    this.storageManager.save(StorageType.tran_filter_p_id, this.getProjectId())
    this.storageManager.save(StorageType.tran_filter_l, this.models.l)
    this.storageManager.save(StorageType.tran_filter_l_id, this.getLotId())
    this.location.back()
  }

  getFilter() {
    this.http.getTraningFilter().subscribe(
      response => {
        console.log(response)
        if (this.http.onCheckSuccess(response)) {
          this.models.lots = response["lot"]
          this.models.projects = response["project"]
          this.models.years = response["year"]

          this.models.y = this.storageManager.load(StorageType.tran_filter_y)
          this.models.p = this.storageManager.load(StorageType.tran_filter_p)
          this.models.l = this.storageManager.load(StorageType.tran_filter_l)
        }
      }
    )
  }

  getYearId() {
    var i = ""
    this.models.years.forEach(item => {
      if (item["year_text"] == this.models.y) {
        i = item["year_id"]
      }
    })
    return i
  }

  getProjectId() {
    var i = ""
    this.models.projects.forEach(item => {
      if (item["name"] == this.models.p) {
        i = item["id"]
      }
    })
    return i
  }

  getLotId() {
    var i = ""
    this.models.lots.forEach(item => {
      if (item["name"] == this.models.l) {
        i = item["id"]
      }
    })
    console.log("RETURN "+i)
    return i
  }
}
